.container {
  width: 300px;
  height: 250px;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.logo {
    display: flex;
    align-items: center;
    color: slateblue;
    font-weight: 700;
    font-size: 1.4rem;
    margin-bottom: 16px;
}

.register-btn {
    border: none;
    padding: 5px;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    background-color: slateblue;
}

.success {
    color: green;
    font-size: 12px;
}

.error {
    color: red;
    font-size: 12px;
}

.cancel-register {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}