.button {
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}
.logout {
  background-color: tomato;
}
.login {
  background-color: teal;
  margin-right: 12px;
}
.register {
  background-color: slateblue;
}
.buttons,
.logout {
  position: absolute;
  top: 10px;
  right: 10px;
}
