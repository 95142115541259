.card {
  width: 250px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

label {
  width: max-content;
  color: tomato;
  font-size: 13px;
  border-bottom: 0.55px solid tomato;
  margin: 3px 0;
}

.username,
.desc {
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.star {
  color: gold;
}

.date {
  font-size: 12px;
}

form {
  width: 250px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

input {
  border: none;
  border-bottom: 1px solid gray;
}

input::placeholder,
textarea::placeholder {
  font-size: 12px;
  color: rgb(153, 149, 149);
}

.submit-button {
  border: none;
  padding: 5px;
  border-radius: 5px;
  color: #fff;
  background-color: tomato;
  cursor: pointer;
}
